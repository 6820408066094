// <reference path="../application.origsrc.js" />
import { IFormObject } from "../../../../user_js/form/IFormObject"

export class FieldModule {

	public select2Destroy( field ) {
		let selector = "#" + $( field ).attr( "id" ).escapeRegExp()
		let elem = $( selector + ".select2, " + selector + ".multiselect2" )
		$( elem ).select2( "destroy" )
	}

	public resetMultiSelectField( field ) {
		$( field ).parent().parent().find( "select" ).each(
			function() {
				const selectedArray = []
				$( this ).find( 'option' ).each( function( index, element ) {
					if( $( element )[ 0 ].defaultSelected ) {
						selectedArray.push( $( element ).val() )
					}
				} )
				$( this ).val( selectedArray )
			}
		)
		$( field ).parent().parent().find( ".select2,.multiselect2" ).trigger( "change" )
		return false
	}

	public resetSelectField( field ) {
		$( field ).parent().parent().find( "select" ).each(
			function() {
				let selectedItem = null
				$( this ).find( 'option' ).each( function( index, element ) {
					if( $( element )[ 0 ].defaultSelected ) {
						selectedItem = $( element ).val()
					}
				} )
				$( this ).val( selectedItem )
			}
		)
		$( field ).parent().parent().find( ".select2,.multiselect2" ).trigger( "change" )
		return false
	}

	public initCheckboxSelect( elem ) {
		if( $( elem ).hasClass( "buttonset" ) && !$( elem ).hasClass( "ui-buttonset" ) ) {
			$( elem ).buttonset()
		}
	}

	public initRadioSelect( elem ) {
		if( $( elem ).hasClass( "buttonset" ) && !$( elem ).hasClass( "ui-buttonset" ) ) {
			$( elem ).buttonset()
			var checkedFields = $( elem ).find( "input:checked" )
			//reload disable field fix
			if( checkedFields.length === 0 ) {
				var inputField = $( elem ).find( "[data-default_checked]" )
				if( inputField.length === 1 && inputField.attr( "checked" ) !== "checked" ) {
					inputField.attr( "checked", true )
					var attrFor = inputField.attr( "id" ).escapeRegExp()
					var labelField = $( elem ).find( "label[for=" + attrFor + "]" )
					labelField.addClass( "ui-state-active" )
				}
			}
		}
	}

	public templateSelectionSelect( item, strNoName ) {
		var style
		var aclass
		var title
		var attrs = ''
		if( item.element ) {
			style = $( item.element ).attr( "style" )
			aclass = $( item.element ).attr( "class" )
			title = $( item.element ).attr( "title" )
			if( item.element.tagName === 'OPTION' ) {
				item.text = $( item.element ).text()
			}
			attrs = ( style ? ' style="' + style + '"' : '' ) + ( aclass ? ' class="' + aclass + '"' : '' ) + ( title ? ' title="' + title + '"' : '' )
		} else {
			if( typeof item.attr == "object" ) {
				var key
				for( key in item.attr ) {
					if( item.attr.hasOwnProperty( key ) ) {
						attrs = attrs + ' ' + key + '="' + item.attr[ key ] + '"'
					}
				}
			}
		}
		if( !item.text && !item.children ) {
			item.text = '<span class="notext">' + strNoName + '</span>'
		}
		var elem = $(
			'<span' + attrs + '>' + item.text + '</span>'
		)
		return elem
	}

	public templateResultSelect( item, strNoName ) {
		var style
		var aclass
		var title
		var attrs = ''
		if( item.element ) {
			style = $( item.element ).attr( "style" )
			aclass = $( item.element ).attr( "class" )
			title = $( item.element ).attr( "title" )
			if( item.element.tagName == 'OPTION' ) {
				item.text = $( item.element ).text()
			}
			attrs = ( style ? ' style="' + style + '"' : '' ) + ( aclass ? ' class="' + aclass + '"' : '' ) + ( title ? ' title="' + title + '"' : '' )
		} else {
			if( typeof item.attr == "object" ) {
				var key
				for( key in item.attr ) {
					if( item.attr.hasOwnProperty( key ) ) {
						attrs = attrs + ' ' + key + '="' + item.attr[ key ] + '"'
					}
				}
			}
		}
		if( !item.text && !item.children ) {
			item.text = '<span class="notext">' + strNoName + '</span>'
		}
		var elem = $(
			'<span' + attrs + '>' + item.text + '</span>'
		)
		return elem
	}

	public resetFieldRange( elem ) {
		let field = $( elem )
		let buttonset = field.parent().parent().find( '.fieldRange' )
		if( buttonset ) {
			let newField = $( '#' + buttonset.attr( "id" ).escapeRegExp() )

			let def = buttonset.data( 'default_value' )

			let minField = newField.find( "#" + buttonset.attr( "id" ).escapeRegExp() + "_min" )
			let maxField = newField.find( "#" + buttonset.attr( "id" ).escapeRegExp() + "_max" )
			let minVal = def.min
			let maxVal = def.max

			if( minField ) {
				minField.val( minVal ).trigger( "change" )
			}
			if( maxField ) {
				maxField.val( maxVal ).trigger( "change" )
			}

		}

	}

	public fieldAbstractReset( elem ) {
		$( elem ).parent().parent().find( "input, select, textarea" ).each(
			function() {
				switch( this.type ) {
					case 'text':
						$( this ).val( this.defaultValue )
					case 'checkbox':
					case 'radio':
						this.checked = this.defaultChecked
				}
				if( this.tagName === 'SELECT' ) {
					var selectedArray = []
					$( this ).find( 'option' ).each( function( index, element ) {
						if( $( element )[ 0 ].defaultSelected ) {
							selectedArray.push( $( element ).val() )
						}
					} )
					$( this ).val( selectedArray )
				}
				$( this ).trigger( 'resetField' )
			}
		)
		return false
	}

	public operatorSwitchFieldDateTimeRange( elem, name ) {
		let minTypeSelector = ( name + "[mintype]" ).escapeRegExp()
		let maxTypeSelector = ( name + "[maxtype]" ).escapeRegExp()
		let operatorSelector = ( name + "[operator]" ).escapeRegExp()
		if( $( elem ).find( "[name=" + operatorSelector + "]:checked" ).val() == "" || $( elem ).find( "[name=" + operatorSelector + "]:checked" ).val() == "!" ) {
			$( elem ).parent().find( ".datetimepicker, select" ).prop( "disabled", false )
			$( elem ).parent().find( "[name=" + minTypeSelector + "]" ).prop( "disabled", false ).trigger( "change" )
			$( elem ).parent().find( "[name=" + maxTypeSelector + "]" ).prop( "disabled", false ).trigger( "change" )
		} else {
			$( elem ).parent().find( "[name=" + minTypeSelector + "]" ).prop( "disabled", true ).trigger( "change" )
			$( elem ).parent().find( "[name=" + maxTypeSelector + "]" ).prop( "disabled", true ).trigger( "change" )
			$( elem ).parent().find( ".datetimepicker, select" ).prop( "disabled", true )
		}

	}

	public cleanDateTimeRange( elem, name ) {
		let parentElement = $( elem ).parent().parent()
		let minTypeSelector = ( name + "[mintype]" ).escapeRegExp()
		let maxTypeSelector = ( name + "[maxtype]" ).escapeRegExp()
		let operatorSelector = ( name + "[operator]" ).escapeRegExp()
		parentElement.find( "input[type=text]" ).val( "" )
		parentElement.find( "select" ).val( "" ).trigger( "change" )
		parentElement.find( "[name=" + minTypeSelector + "][value='cal']" ).prop( "checked", true ).trigger( "change" )
		parentElement.find( "[name=" + maxTypeSelector + "][value='cal']" ).prop( "checked", true ).trigger( "change" )
		parentElement.find( "[name=" + operatorSelector + "][value='']" ).prop( "checked", true ).trigger( "change" )
		return false
	}

	public getSelectedValueTreeSelect( treeViewContainer: string, treeForm: string, formId: string, fieldId: string, endNodes: boolean, maxValues: number ): boolean {
		let select = $( "#" + formId + " select[id='" + fieldId.escapeRegExp() + "']" )
		let fieldFunctions = this.getForm( formId ).fieldFunctions[ fieldId.replaceAll( /[\[\]]/g, "" ) ]
		let form = $( "#" + treeForm )
		if( form.length ) {
			let selected = {
				"id": [],
				"dataid": [],
				"text": []
			}
			$( treeViewContainer ).hummingbird( "getChecked", { list: selected, onlyEndNodes: endNodes } )
			if( selected.dataid.length ) {
				//console.log(selected.dataid.length);
				if( selected.dataid.length > maxValues ) {
					app.warning_message( app.get_lang( 'tree.select.warning' ) + "(" + selected.dataid.length + ") max " + maxValues )
					return false
				} else {
					if( fieldFunctions && fieldFunctions.hasOwnProperty( "destroy" ) && ( typeof fieldFunctions.destroy === "function" ) ) {
						fieldFunctions.destroy( select )
					}
					selectedValues = []
					selected.dataid.forEach( function( itemId, index ) {
						let optionName = selected.text[ index ]
						// Set the value, creating a new option if necessary
						if( select.find( "option[value='" + itemId + "']" ).length ) {
							selectedValues.push( itemId )
						} else {
							// Create a DOM Option and pre-select by default
							let newOption = new Option( optionName, itemId, false, false )
							// Append it to the select
							select.append( newOption )
							selectedValues.push( itemId )
						}
					} )
					select.val( selectedValues )
					if( fieldFunctions && fieldFunctions.hasOwnProperty( "init" ) && ( typeof fieldFunctions.init === "function" ) ) {
						fieldFunctions.init( select )
					}
				}
			}
		}

		return true
	}

	private getForm( formId: string ): IFormObject {
		return window[ formId ]
	}

}