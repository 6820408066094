import * as React from "react"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { PermissionData } from "../Permission"
import { MobileLinkFactory } from "../MobileLinkFactory"
import MobileCrmData from "../MobileCrmData"
import { MobileConfirmDialog } from "../dialog/MobileConfirmDialog"
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { FormattedMessage } from "react-intl"
import { crmApi } from "../api/CrmApi"
import { AxiosResponse } from "axios"

export type ListItemMenuData = {
	permission?: PermissionData, table: string, id: number, index: number, onDeleteItemCallback: ( index: number ) => void,
	menuVisible: boolean, setMenuVisible: ( menuVisible: boolean ) => void
}

type EntityDeleteResponse = { status: boolean }

const ListItemMenu = ( props: ListItemMenuData ) => {
	const [ showDialog, setShowDialog ] = useState<boolean>( false )

	const canEdit = props.permission?.canEdit
	const canDelete = props.permission?.canEdit

	const confirmCallback = () => {
		const config = {
			"table": props.table,
			"id": props.id,
		}
		crmApi.post( MobileLinkFactory.createActionLink( MobileCrmData.DELETE_ENTITY_ACTION ), config ).then( ( response: AxiosResponse<EntityDeleteResponse> ) => {
			if( response?.data?.status ) {
				props.onDeleteItemCallback( props.index )
			}
		} ).catch( ( error ) => {
			console.log( error.message )
		} )
	}
	return (
		<>
			<div className={ "itemMenu" } onClick={ () => {
				props.setMenuVisible( !props.menuVisible )
				setTimeout( () => {
					props.setMenuVisible( false )
				}, 4000 )
			} }>
				{ canEdit ? <>
					<FontAwesomeIcon icon={ faEllipsisV } />
					{ props.menuVisible &&
						<div className={ "menu" } style={ { display: "inline-grid" } }>
						<span>
							<Link
								key={ props.table }
								to={ MobileLinkFactory.createFormLink( props.table, props.id ) }
							>
								<FormattedMessage defaultMessage={ "Upravit" } id={ "listItemEdit" } />
							</Link>
						</span>
							{ canDelete ? <span
								onClick={ () => {
									setShowDialog( true )
								}
								}
							>
							<FormattedMessage defaultMessage={ "Smazat" } id={ "listItemDelete" } />
						</span> : undefined }
						</div>
					}
				</> : undefined }
			</div>

			<MobileConfirmDialog
				show={ showDialog }
				confirmCallback={ confirmCallback }
				showCallback={ setShowDialog }
				message={ <FormattedMessage defaultMessage={ "Opravdu si přejete smazat záznam? " } id={ "listItemDeleteConfirm" } /> }
				confirmButtonText={ <FormattedMessage defaultMessage={ "Smazat" } id={ "listItemDelete" } /> }
			/>
		</>

	)
}

export default ListItemMenu
