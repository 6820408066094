/// <reference path="../application.origsrc.js" />

// @ts-nocheck

import { DOMElement } from "react"
import { IFormObject } from "../../../../user_js/form/IFormObject"

export class FormModule {

	//form object
	private formObject: IFormObject

	private noValidationErrorDialog: boolean = false

	constructor( formId: string ) {
		this.formId = formId
		console.log( 'INIT form module' ) //todo vz debug
	}

	private getForm(): IFormObject {
		if( this.formObject === undefined ) {
			this.formObject = window[ this.formId ]
		}

		return this.formObject
	}

	setNoValidationErrorDialog( value: boolean ) {
		this.noValidationErrorDialog = value
	}

	removeErrorHints( elem: DOMElement ) {
		let field = $( elem )
		field.removeClass( "error" )
		if( this.noValidationErrorDialog ) {
			let id = field.attr( "id" )
			if( id ) {
				field.nextAll( ".error_hint" ).remove()
			}
		}
	}

	public formInit( defaultSubmit: boolean ): void {
		const _this = this
		const fields = $( "#" + this.formId + "  input[type!=button], #" + this.formId + "  select, #" + this.formId + "  span.select2, #" + this.formId + "  textarea" )

		fields.bind( "change keypress", function() {
			_this.removeErrorHints( this )
		} )
			.bind( "focus click", function() {
				$( "#form_hint" ).remove()

				let elem = $( this )
				let html = elem.data( "hint" )
				if( elem.parents( ".field" ).length > 0 ) {
					html = elem.parents( ".field" ).find( '[data-hint]' ).data( "hint" )
					elem = elem.parents( ".field" ).first()
				} else {
					if( elem.is( "select.chosen-select, select.chosen-multiselect, select.select2, select.multiselect2" ) ) {
						elem = elem.parent().find( ".chosen-container, .select2-container" ).first()
					} else {
						if( elem.parents( ".chosen-container" ).length > 0 ) {
							html = elem.parents( ".chosen-container" ).first().prev().data( "hint" )
							elem = elem.parents( ".chosen-container" ).first()
						} else {
							if( elem.is( ".select2-container" ) ) {
								html = elem.prev().data( "hint" )
							}
						}
					}
				}
				if( !html ) {
					elem = elem.parent()
					html = elem.data( "hint" )
				}

				if( html ) {
					let hintElem = elem.parents( '.hintElem' ).first()
					if( hintElem.length === 0 ) {
						hintElem = elem
					}
					hintElem.after( '<div id="form_hint"><div class="inner">' + html + '</div></div>' )
					const offset = hintElem.offset()
					const left = "left"
					const top = "top"
					if( hintElem.parents( ".ui-dialog" ).length > 0 ) { // form in dialog
						$( "#form_hint .inner" ).addClass( "white" )
						const position = hintElem.parents( ".ui-dialog" ).first().position()
						const titlebarHeight = hintElem.parents( ".ui-dialog" ).first().find( ".ui-dialog-titlebar" ).outerHeight()
						//left += "-" + parseInt( position.left );
						//top += "-" + (parseInt( position.top ) + titlebarHeight);
						const width = hintElem.width()
						$( "#form_hint" ).css( "position", "absolute" )
							.css( "top", offset.top - ( parseInt( position.top ) + titlebarHeight ) + 20 )
							.css( "left", offset.left + width - parseInt( position.left ) + 30 ).show()
					} else {
						$( "#form_hint" ).position( {
							of: hintElem,
							my: left + " " + top,
							at: "right+30 top"
						} ).show()
					}
					//$("#form_hint").css("position", "absolute").css("top", offset.top + 20).css("left", offset.left + width + 30).show();
//			$( "#form_hint" ).position( {
//				of: elem,
//				my: left + " " + top,
//				at: "right+30 top"
//			} ).show();
				}
			} )
			.bind( "focus", function() { // colored background
				$( "#" + _this.formId + "  input, #" + _this.formId + "  select, #" + _this.formId + "  textarea" ).removeClass( "selected_item" )
				$( this ).addClass( "selected_item" )
			} )
			.bind( "blur", function() {
				$( "#form_hint" ).remove()
			} )

		$( "#" + this.formId + "  input[type!=button]" ).keypress( function( event ) { // submit on enter
			if( event.which === 13 && defaultSubmit ) {
				if( $( this ).hasClass( "ui-autocomplete-input" ) ) {
					return false
				}
				_this.getForm().submit()
			}
			return true
		} )
		$( "#" + this.formId + "  input, #" + this.formId + "  select, #" + this.formId + "  textarea" )
			.change( function( event ) {
				_this.getForm().submitInfo()
			} )
		$( "#" + this.formId + "  input[readonly=readonly]" ).css( "backgroundColor", "#EEEEEE" )
	}

	public filterFormInit(): void {
		const _this = this
		let fields = $( "#" + this.formId + " input[type!=button], #" + this.formId + " select, #" + this.formId + " span.select2, #" + this.formId + " textarea" )
		fields.bind( "change", function() {
			_this.removeErrorHints( this )
		} )
			.bind( "focus click", function() {
				$( "#form_hint" ).remove()
				let elem = $( this )
				let html = elem.data( "hint" )
				if( elem.parents( ".field" ).length > 0 ) {
					html = elem.parents( ".field" ).find( '[data-hint]' ).data( "hint" )
					elem = elem.parents( ".field" ).first()
				} else {
					if( elem.is( "select.chosen-select, select.chosen-multiselect, select.select2, select.multiselect2" ) ) {
						elem = elem.parent().find( ".chosen-container, .select2-container" ).first()
					} else {
						if( elem.parents( ".chosen-container" ).length > 0 ) {
							html = elem.parents( ".chosen-container" ).first().prev().data( "hint" )
							elem = elem.parents( ".chosen-container" ).first()
						} else {
							if( elem.is( ".select2-container" ) ) {
								html = elem.prev().data( "hint" )
							}
						}
					}
				}
				if( !html ) {
					elem = elem.parent()
					html = elem.data( "hint" )
				}

				if( html ) {
					let hintElem = elem.parents( '.hintElem' ).first()
					if( hintElem.length === 0 ) {
						hintElem = elem
					}
					hintElem.after( '<div id="form_hint"><div class="inner">' + html + '</div></div>' )
					const offset = hintElem.offset()
					if( hintElem.parents( ".ui-dialog" ).length > 0 ) { // form in dialog
						$( "#form_hint .inner" ).addClass( "white" )
					}
					const width = hintElem.width()
					$( "#form_hint" ).css( "position", "absolute" ).css( "top", offset.top + 20 ).css( "left", offset.left + width + 30 ).show()
				}
			} )
			.bind( "focus", function() { // colored background
				$( "#" + _this.formId + " input, #" + _this.formId + " select, #" + _this.formId + " textarea" ).removeClass( "selected_item" )
				$( this ).addClass( "selected_item" )
			} )
			.bind( "blur", function() {
				$( "#form_hint" ).remove()
			} )

		$( "#" + this.formId + " input[type!=button]" )
			.keypress( function( event ) { // submit on enter
				if( event.which == 13 && "{$this->defaultSubmit}" ) {
					if( $( this ).hasClass( "ui-autocomplete-input" ) ) {
						return false
					}
					_this.getForm().submit()
				}
			} )
		$( "#" + this.formId + " input[readonly=readonly]" ).css( "backgroundColor", "#EEEEEE" )
	}

	public enableOperatorFields( elem, containShow, baseElement ) {
		const element = $( elem )
		let typeSelect = element.is( "select" )
		let typeText = element.attr( 'type' ) === 'text'
		let values
		let containField = null
		if( typeSelect === true || typeText === true ) {
			values = element.val()
		} else {
			values = element.find( 'input:checked' )
		}
		if( !baseElement ) {
			baseElement = element
		}

		if( containShow === true ) {
			containField = baseElement.parent( 'div' ).find( "span[name*='contain']" )
		}

		let operator = baseElement.parent( 'div' ).find( "span[name*='operator']" )

		if( values && values.length ) {
			operator.buttonset( "enable" )
			if( containField && containShow === true ) {
				containField.buttonset( "enable" )
			}
		} else {
			operator.buttonset( "disable" )
			if( containField && containShow === true ) {
				containField.buttonset( "disable" )
			}
		}
	}

}